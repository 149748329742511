import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import DataService from "../services";

const initialState = {
    idPathActive: '1',
    pathTree: DataService.getData(0),
    modeRevelated: true,
    indexTree: 0
}

const reducerSentence = createSlice({
    name: 'sentence',
    initialState,
    reducers: {
        addingPathTree : (state,action) => {
            let pathTreeUpdated = _.cloneDeep(state.pathTree);
            const nodeAppend = {...findNodeById(DataService.getData(state.indexTree)[0], action.payload),children:[]};
            const nodeSearch = findNodeById(pathTreeUpdated[0], state.idPathActive);
            nodeSearch.children[0] = nodeAppend;
            
            return {
                ...state,
                pathTree: pathTreeUpdated,
                idPathActive: action.payload
            }
        },
        updatingModeRevelated: (state) => {
            return {
                ...state,
                pathTree: DataService.getData(state.indexTree),
                modeRevelated: true
            }
        },
        updatingModeDiscovery: (state) => {
            return {
                ...state,
                pathTree: [{...findNodeById(DataService.getData(state.indexTree)[0],'1'),children:[]}],
                idPathActive: '1',
                modeRevelated: false
            }
        },
        selectingNodes: (state,action) => {
            if(action.payload === 'clean-all') {
                return {
                    ...state,
                    pathTree: DataService.getData(state.indexTree),
                }
            } else {
                let pathTreeUpdated = _.cloneDeep(state.pathTree);
                updatingNodeSelected(pathTreeUpdated[0],'-1',action.payload);
                return {
                    ...state,
                    pathTree: pathTreeUpdated,
                }
            }
        },
        selectingTree: (state,action) => {
            let tree;
            if(state.modeRevelated === true) {
                tree = DataService.getData(action.payload)
            } else {
                tree = [{...findNodeById(DataService.getData(action.payload)[0],'1'),children:[]}]
            }
            return {
                ...state,
                pathTree: tree,
                indexTree: action.payload
            }
        }
    }
})

export function findNodeById(tree, id) {

    let result = null
    if (tree.id === id) {
         return tree;
    }
 
    if (Array.isArray(tree.children) && tree.children.length > 0) {
       tree.children.some((node) => {
         result = findNodeById(node, id);
         return result;
       });
    }
    return result;
}


export function updatingNodeSelected(tree, id, value) {

    if(tree.label.includes(value) === true) {
        tree.selected = true;
        tree.className = 'div-border-color-red-500';
    } else {
        tree.selected = false;
        tree.className = 'div-border-color-green-500';
    }

    let result = null
    if (tree.id === id) {
         return tree;
    }
 
    if (Array.isArray(tree.children) && tree.children.length > 0) {
       tree.children.some((node) => {
         result = updatingNodeSelected(node, id, value);
         return result;
       });
    }
    return result;
}

export const pathTree = (state) => state.sentence.pathTree;
export const idPathActive = (state) => state.sentence.idPathActive;
export const modeRevelated = (state) => state.sentence.modeRevelated;
export const {addingPathTree,updatingModeRevelated,updatingModeDiscovery,selectingNodes,selectingTree} = reducerSentence.actions

export default reducerSentence.reducer