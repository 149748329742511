import React, { useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { selectingNodes } from '../../reducers';

export default function DropdowndData() {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null);
    const datas = [
        { name: 'AgCl', code: 'AgCl' }
    ];

    const selectedTemplate = (option, props) => {
        if (option) {
          return (
            <div className="flex align-items-center">
              <div dangerouslySetInnerHTML={{__html: option.name}}></div>
            </div>
          );
        }
    
        return <span>{props.placeholder}</span>;
      };
    
      const optionTemplate = (option) => {
        return (
          <div className="flex align-items-center">
           <div dangerouslySetInnerHTML={{__html: option.name}}></div>
          </div>
        );
      };

    return (
        <div className="card flex justify-content-center">
            <Dropdown value={selected} onChange={(e) => {
                setSelected(e.value)
                const value = (e.value) ? e.value.name : 'clean-all';
                dispatch(selectingNodes(value))
            }} options={datas} optionLabel="name" 
                placeholder="Selecione Substância" showClear className="border-round-sm w-full md:w-14rem"
                valueTemplate={selectedTemplate}
                itemTemplate={optionTemplate} />
        </div>
    )
}