import {configureStore} from '@reduxjs/toolkit';

import reducerSentence from './reducers/';

const store = configureStore({
    reducer: {
        sentence : reducerSentence
    }
});

export default store;