import React,{useState} from 'react';
import {Button} from 'primereact/button';

const ButtonAnswer = (props) => {
    const [disabledButton,setDisabledButton] = useState(false);
    const id = props.data.id;

    return (
        <Button id={id} disabled={disabledButton} onClick={() => {
                setDisabledButton(true);
                props.data.callHandleOnClick(id);}} className='mx-1 my-1 select-none text-sm font-bold'>
            <span data-pc-section="label" dangerouslySetInnerHTML={{__html: props.data.label}}/>
        </Button>
    );
    
};

export default ButtonAnswer;