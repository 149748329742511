import * as _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { addingPathTree} from '../../reducers';
import ButtonAnswer from '../ButtonAnswer';
import DialogLink from '../DialogLink';
import { useSelector } from 'react-redux';
import { pathTree,findNodeById,modeRevelated } from '../../reducers';



const Sentence = (props) => {
    const pathTreeReadyOnly = useSelector(pathTree);
    const modeRevelatedReadyOnly = useSelector(modeRevelated);
    const idContent = `node-content-${props.node.id}`;
    const idHeaderContent = `header-node-content-${props.node.id}`;
    let idButtonList = [];

    const dispatch = useDispatch();

    const callHandleOnClickQuestion = (idButtonInvoked) => {
        idButtonList.forEach((item) => {
            document.getElementById(item).style.display = 'none';
        });

        dispatch(addingPathTree(idButtonInvoked));
    }

    const showAnswers = (node) => {
        let questionButtonList = [];
        
        if(modeRevelatedReadyOnly === false) {
        
            if(node.data) {
                node.data.forEach((item,index) => {
                    const ajustId = index + 1;
                    const idButton = `${node.id}.${ajustId}`;
                    idButtonList.push(idButton);
                    const data = {
                        id: idButton,
                        label: item.value,
                        callHandleOnClick: callHandleOnClickQuestion
                    };
                    questionButtonList.push(<ButtonAnswer data={data}></ButtonAnswer>);
                });
            }
        }
        
        return questionButtonList;
    }
    
    const showInfo = (node) => {
        let tag = <React.Fragment/>;
        const link = _.filter(node.links,(item) => item.type === 'button')[0];

        if(link) {
            tag = <DialogLink link={link}></DialogLink>
        }

        return tag;
    }

    const showImage = (node) => {
        if(node.image) {
            return <img src={node.image.src} alt={node.image.alt} className="w-4rem h-4rem" />;
        } else {
            return <React.Fragment/>
        }                
    }

    const alternateMerge = (arr1, arr2, n1 = arr1.length, n2 = arr2.length) => {
        let i = 0, j = 0;
        let temp = [];
        
        //Add each element from both the array
        while(i < n1 && j < n2){
          temp.push(arr1[i], arr2[j]);
          i++;
          j++;
        }
        
        //If there is still element in first array
        //then add them
        while(i < n1){
          temp.push(arr1[i]);
          i++;
        }
        
        //If there is still element in second array
        //then add them
        while(j < n2){
          temp.push(arr2[j]);
          j++;
        }
        
        return temp;
      }

    const headerClassName = (node) => {
      if(node.selected && node.selected === true) {
        return 'div-background-red-500';
      } else {
        return 'div-background-green-500';
      }
    }
    
    const showParentAnswer = (node) => {
       
            if(node && node.root && node.root === true) {
                return <React.Fragment></React.Fragment>;
            } else {
                const id = node.id;
                const idParent = id.substring(0,id.lastIndexOf('.'));
                const idxDataParent = Number(node.id.substring((node.id.length - 1),node.id.length)) - 1;
                const nodeParent = findNodeById(pathTreeReadyOnly[0],idParent);

                return <div id={idHeaderContent} className={headerClassName(node)}><label className='select-none'>
                            <span data-pc-section="label" dangerouslySetInnerHTML={{__html: nodeParent.data[idxDataParent].value}}/></label>
                        </div>;
            }
       
    };

    const showSentence = (node) => {
        if (node.links) {
          let labelFormatado = node.label;

          
          
          const links = _.map(_.filter(node.links,(item) => item.type === 'link'), (link) => {
            return link.label;
          });

          links.forEach((link) => {
            labelFormatado = _.replace(labelFormatado, link, "||");
          });
    
          const labels = _.split(labelFormatado, "||");

          let tagsLabels = [];

          labels.forEach((label) => {
            tagsLabels.push(
              
                <span data-pc-section="label" dangerouslySetInnerHTML={{__html: label}}></span>
             
            );
          });

          let tagsLinks = [];

          _.filter(node.links,(item) => item.type === 'link').forEach((item) => {
            tagsLinks.push(<DialogLink link={item} visible={false}></DialogLink>);
          });

          const concatTagsLabelsLinks = alternateMerge(tagsLabels,tagsLinks);
    
          return <div className='pl-1 pr-1'><label className="select-none font-bold">{concatTagsLabelsLinks}</label></div>;
        } else {
          return <div className='pl-1 pr-1'><label className='select-none font-bold'><span data-pc-section="label" dangerouslySetInnerHTML={{__html: props.node.label}}/></label></div>;
        }
    };

    
    let tmp = ((props.node.root === true) || (modeRevelatedReadyOnly && modeRevelatedReadyOnly ===true)) ? 'block' : 'none';

    return (
        <div className="node-content" id={idContent} style={{display:tmp}}>
            {showParentAnswer(props.node)}
            {showInfo(props.node)}
            {showImage(props.node)}
            {showSentence(props.node)}
            {showAnswers(props.node)}
        </div>
    );
};

export default Sentence;