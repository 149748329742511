export const DataService = {
    
    
    getData(index) {
        if(index === 0) {
            return this.treeData0;
        } else {
            return this.treeData1;
        }
    },

    treeData0: [
        {
            id: '1',
            root: true,
            label: 'Mistura de Cátios do Grupo I </br>Ag<sup>+</sup>, Hg<sub>2</sub><sup>2+</sup> e Pb<sup>2+</sup></br> Adicione 6 M de HCl, centrifugue e decante',
            expanded: true,
            className: 'div-border-color-green-500',
            data: [
                {
                    value: 'precipitado'
                },
                {
                    value: 'solução a ser descartada'
                }
            ],
            links: [
                {
                    label: 'centrifugue',
                    header: 'Dicas de Centrifugação',
                    content: '<p>Centrigurar, aumentando a rotação gradativamente!</p>',
                    type: 'link'
                }
            ],
            children: [
                {
                    id: '1.1',
                    className: 'div-border-color-green-500',
                    label: 'AgCl,Hg<sub>2</sub>Cl<sub>2</sub> e PbCl<sub>2</sub></br>Adicione água quente, centrifugue e decante',
                    expanded: true,
                    data: [
                        {
                            value: 'precipitado'
                        },
                        {
                            value: 'solução'
                        }
                    ],
                    links: [
                        {
                            label: 'centrifugue',
                            header: 'Dicas de Centrifugação',
                            content: '<p>Centrigurar, aumentando a rotação gradativamente!</p>',
                            type: 'link'
                        }
                    ],
                    children: [
                        {
                            id: '1.1.1',
                            className: 'div-border-color-green-500',
                            label: 'AgCl,Hg<sub>2</sub>Cl<sub>2</sub>'
                        },
                        {
                            id: '1.1.2',
                            className: 'div-border-color-green-500',
                            label: 'Pb<sup>2+</sup></br>Adicione CrO<sub>4</sub><sup>2-<sup>',
                            expanded: true,
                            data: [
                                {
                                    value: 'precipitado amarelo'
                                }
                            ],
                            children: [
                                {
                                    id: '1.1.2.1',
                                    className: 'div-border-color-green-500',
                                    label: 'Confirma Pb<sup>2+</sup>'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: '1.2',
                    className: 'div-border-color-green-500',
                    label: '',
                    expanded: true,
                }
            ]
        }
    ]
};

export default DataService;