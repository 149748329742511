import React from 'react';
import ReactDOM from 'react-dom/client';
import OrganizationChartCustom from './components/OrganizationChartCustom';
import './styles/app.css'
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";//aqui
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Provider } from 'react-redux';
import store from './store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <div className="flex align-items-center justify-content-center">
          <OrganizationChartCustom></OrganizationChartCustom>
    </div>
    </Provider>
 </React.StrictMode>
);