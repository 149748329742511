import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const DialogLink = (props) => {
    const [visibleDialog, setVisibleDialog] = useState(props.visible);
    const showDialogLink = (link) => {
        if (link.type === 'button') {
            return <Button tooltip='Clique para maiores informações!' type='button' icon="pi pi-align-justify" rounded aria-label="User" 
            onClick={() => setVisibleDialog(true)} />
        } else {
            return <a className='no-underline text-primary transition-colors hover:bg-orange-500 hover:text-white' href="#" onClick={() => setVisibleDialog(true)}>{link.label}</a>
        }
    }

    return (<React.Fragment>
        {showDialogLink(props.link)}
        <Dialog modal={true} header={props.link.header} visible={visibleDialog} style={{ width: '50vw' }} onHide={() => setVisibleDialog(false)}>
        <span  dangerouslySetInnerHTML={{__html: props.link.content}}></span>
        </Dialog>
    </React.Fragment>);
};

export default DialogLink;