import { Panel } from 'primereact/panel';
import React, { useEffect} from 'react';
import Sentence from '../Sentence';
import {OrganizationChart}  from 'primereact/organizationchart';
import { useSelector,useDispatch } from 'react-redux';
import { idPathActive, pathTree,updatingModeDiscovery,updatingModeRevelated,modeRevelated } from '../../reducers';
import {Button} from 'primereact/button';
import DropdowndData from '../DropdownData';
import DropdowndSelector from '../DropdownSelector';


export const OrganizationChartCustom = () => {
    const pathTreeReadyOnly = useSelector(pathTree);
    const idPathActiveReadyOnly = useSelector(idPathActive);
    const modeRevelatedReadyOnly = useSelector(modeRevelated);
    const nodeTemplate = (node) => {
        return <Sentence node={node} />;
    };

    const dispatch = useDispatch();

    const showDropdown = () => {
        if(modeRevelatedReadyOnly === true) {
            return <DropdowndData className="pl-2"/>
        } else {
            return <React.Fragment/>
        }
    }

    const headerTemplate3 = () => {
        const content2 =
        <React.Fragment>
            <div className="p-panel-header" data-pc-section="header">
            <div class="flex justify-content-end flex-wrap">
                {/* 
                <span id="pr_id_15_header" class="p-panel-title" data-pc-section="title">
                    <div className='font-bold' class="flex align-items-center">
                        <span style={{paddingLeft: '10px'}}>ESCRITURAÇÃO DE NOTA FISCAL</span>
                    </div>
                </span>
                */}
                <DropdowndSelector/>
                {showDropdown()}
                
                <div className="p-panel-icons" data-pc-section="icons">
                    
                    <span className="p-buttonset mx-1">
                        <Button rounded icon="pi pi-sitemap" disabled={modeRevelatedReadyOnly} onClick={() => {
                            dispatch(updatingModeRevelated());
                        }}/>
                        <Button rounded icon="pi pi-search" disabled={!modeRevelatedReadyOnly} onClick={() => {
                            dispatch(updatingModeDiscovery());
                        }}/>
                    </span>

                    <Button outlined icon="pi pi-print" rounded className='mx-1 bg-primary' tooltip='Imprimir' onClick={() => {
                            window.print()
                        }}/>
                        
                    <Button outlined icon="pi pi-undo" rounded className='bg-primary' tooltip='Reiniciar' onClick={() => {window.location.reload()}}/>
                </div>
            </div>
            </div>
        </React.Fragment>
        return content2;
    }
    
    useEffect(() => {
        const idContent = `node-content-${idPathActiveReadyOnly}`;
        if(document.getElementById(idContent)) {
            document.getElementById(idContent).style.display = 'block';
        }
    },[idPathActiveReadyOnly]);
    
    
    //useEffect(() => {
        //console.log(JSON.stringify(pathTreeReadyOnly,null,4));
        //const selectors = document.querySelectorAll(".p-node-toggler");
        //console.log(selectors);
        //if(selectors && selectors.length > 0) {
        //    console.log('retirando.......');
            //selectors.forEach(el => el.remove());
            //document.querySelectorAll(".p-node-toggler").forEach(el => el.remove());
        //}
        
    //},[pathTreeReadyOnly]);
    
    return (
        <div className='fadein animation-duration-1000'>
            <Panel headerTemplate={headerTemplate3}> 
            <OrganizationChart nodeTemplate={nodeTemplate} value={pathTreeReadyOnly} ></OrganizationChart>
            </Panel>
        </div>
    );
};

export default OrganizationChartCustom;